.deploy{
	margin-top: 5vh;
}

@include media( '<medium' ) {

}

@include media( '>=medium' ) {

}
