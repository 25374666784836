.capabilities-group {
    // margin-top: -32px;
}

.capabilities-list {
    column-count: 1;
    margin: auto;
    // margin-top: -16px;
}

.capabilities-note {
    position: relative;
    top: 2em;
    margin: auto;
    text-align: center;
}

@include media( '>medium' ) {
    .capabilities-list {
        column-count: 2;
        width: 70%;
        margin-left: 20%;
    }
}