.design{
	margin-top: 10vh;
}
@include media( '<medium' ) {
.cs-gridWrapper {
		display: none;
	}

.cs-bgImage{
	position:absolute;
	opacity: 40%;
	margin-left: 50vw;
	margin-top: -2vh;
	width: 150px;
	display: none;
}
	
}

@include media( '>=medium' ) {
.cs-carouselWrapper {
		display: none;
	}


}
