.first-contact-inner {
	background-color: get-color(dark, 3);
	padding: 40px;
	border-radius: 20px;

	
}

@include media( '<=medium' ) {

	
}

@include media( '>medium' ) {



}
