@include media( '<=medium' ) {
    .portrait {
        width: 70%;
        clear: both;    
    }    
    .center-content {
        display: block;
        margin-left: auto;
        margin-right: auto;        
    }
}

@include media( '>medium' ) {
    .portrait {
        width: 35%;
        float: left;
        margin-right: 32px;
    }
}