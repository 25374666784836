.portfolio {

	.section-inner {
		padding-top: $portfolio--padding-t__mobile;
		padding-bottom: $portfolio--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $portfolio-header--padding__mobile;
	}

	.tiles-wrap {
		@if ( $portfolio-items--padding__mobile != null ) {
			margin-right: -($portfolio-items--padding__mobile * 0.5);
			margin-left: -($portfolio-items--padding__mobile * 0.5);
			margin-top: -($portfolio-items--padding__mobile * 0.5);

			&:last-of-type {
				margin-bottom: -($portfolio-items--padding__mobile * 0.5);
			}

			&:not(:last-of-type) {
				margin-bottom: ($portfolio-items--padding__mobile * 0.5);
			}
		}

		&.push-left {

			&::after {
				flex-basis: $portfolio-item--width;
				max-width: $portfolio-item--width;
				@if ( $portfolio-items--padding__mobile != null ) {
					padding-left: $portfolio-items--padding__mobile * 0.5;
					padding-right: $portfolio-items--padding__mobile * 0.5;
				}
			}
		}
	}

	.tiles-item {
		flex-basis: $portfolio-item--width;
		max-width: $portfolio-item--width;
		@if ( $portfolio-items--padding__mobile != null ) {
			padding: $portfolio-items--padding__mobile * 0.5;
		}
	}

	.tiles-item-inner {
		padding-top: $portfolio-item--inner-padding-v;
		padding-bottom: $portfolio-item--inner-padding-v;
		padding-left: $portfolio-item--inner-padding-h;
		padding-right: $portfolio-item--inner-padding-h;
	}
}

.portfolio-item-content {
	flex-grow: 1;
}

.portfolio-item-source {
	display: inline-flex; // to allow centering when using .content-center on a parent tag
	flex-wrap: wrap;
	align-items: center;
}

@include media( '>medium' ) {

	.portfolio {

		.section-inner {
			padding-top: $portfolio--padding-t__desktop;
			padding-bottom: $portfolio--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $portfolio-header--padding__desktop;
		}

		@if ( $portfolio-items--padding__desktop != null ) {

			.tiles-wrap {
				margin-right: -($portfolio-items--padding__desktop * 0.5);
				margin-left: -($portfolio-items--padding__desktop * 0.5);
				margin-top: -($portfolio-items--padding__desktop * 0.5);

				&:last-of-type {
					margin-bottom: -($portfolio-items--padding__desktop * 0.5);
				}

				&:not(:last-of-type) {
					margin-bottom: ($portfolio-items--padding__desktop * 0.5);
				}

				&.push-left {

					&::after {
						padding-left: $portfolio-items--padding__desktop * 0.5;
						padding-right: $portfolio-items--padding__desktop * 0.5;
					}
				}
			}

			.tiles-item {
				padding: $portfolio-items--padding__desktop * 0.5;
			}
		}
	}
}

.projectCard{
	border: none !important;
	background-color: transparent !important;
	text-shadow: 0px 0px 15px black
}
.tintMinor{
	opacity: 0.85;
}
.tintMajor{
	opacity: 0.7;
}
.projectGrid{
	display: inline-block;
	width: 25%;
	margin-top: 30px;
	
	@include media('<medium') {
		width: 70%;
	}
}

.projectButton{
	background-color: transparent;
	
	cursor: pointer;
}

.caseStudyCard{
	border: none !important;
	background-color: black;
	width: 100%;
	
	@include media('<medium') {
			height: 400px;
			
		}
}

.caseStudyImg{
	object-fit: cover;
	width: 100%;
	@include media('<medium') {
			height: 100%;
			object-position: -300px;
		}
}
.caseStudyImgGradient{
	display: block;
	position: relative;
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgb(0, 0, 0) 100%);
	margin-top: -300px;
	height: 300px;
	@include media('<medium') {
		margin-top: -250px;
		height: 250px;
	}
	width: 100%;
	content: '';
}

.caseStudyTitle{
	font-weight: bold;
	font-size: 48px;
	
	@include media('<medium') {
			font-size: 36px;
			position: relative;
			top: 10px;
		}
}
.caseStudyText{
	@include media('<medium') {
			font-size: 20px;
			margin-bottom: 0;
		}
}

.caseStudyButton{
	margin-bottom: 0px !important;
	@include media('<medium') {
			margin-top: 16px;
			
	}
}

.projectTitle{
	font-weight: bold;
	text-shadow: 0px 0px 10px #000;
	font-size: 1rem;
	margin-top: 40%;
		@include media('>large') {
			font-size: 1.4rem;
		}
		@include media('<=large') {
			font-size: .7rem;
		}
				@include media('<=medium') {
					font-size: 1rem;
				}
}

.modal-close-icon {
	padding: 5px;

	@include media('<medium') {
		background-color: black;
		border-radius: 10px;

		position: fixed;
		right: 15px;

	}

}