.portfolio {

	.tiles-item-inner {
		background: get-color(dark, 2);		
	}
}

.portfolio-item-content {
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 30%;
        height: 18px;
        margin-top: 6px;
        margin-bottom: 16px;
        background-repeat: no-repeat;
    }		
}

.portfolio-item-footer {
    font-weight: 600;
    padding-top: 20px;

    &.has-top-divider {

        &::before {
            background: get-color(dark, 3);
        }
    }
}

.portfolio-item-link {
	color: get-color(secondary, 3);

	a {
        color: get-color(secondary, 3);
        text-decoration: none;

        &:hover {
            color: get-color(secondary, 2);
        }
	}
}
