.projectOverlay {
    background: rgba(32, 32, 32, 0.96);
}

.projectModal {
    background: #000000;
    max-width: $container--width;
    width: 100%;
    
    margin: 0 auto;
    overflow: hidden;
    border-radius: 15px;
    transition: bottom 0.3s ease-out

}

.projectVideo{
    width: 63%;
    
    float:left;
    padding-right: 10px;

}

.gridWrapper{
    width:35%;
    float:right;
    
}





@include media('>=medium') {
    .carouselWrapper{
        display:none;
    }
}

@include media('<medium') {
    .gridWrapper {
        display: none;
        width:0;
    }
    .projectVideo{
        width:100%;
        margin: 0 auto;
        margin-top:-30px;
        margin-bottom: 10px;
        padding: 0;
        float:none;
    }
    .projectModal{
        margin: 0;
        
        border-radius: 0;
    }

    .carouselWrapper{
        width: 100%;
        margin: 0 auto;
        margin-bottom: 50px;
    }

  
}

@keyframes customEnterModalAnimation {
    0% {
        margin-top: 100%;
        opacity: 0;
    }

    100% {
        margin-top: 5%;
        opacity: 1;
    }
}

@keyframes customLeaveModalAnimation {
    0% {
        margin-top: 5%;
        opacity: 1;
    }

    100% {
        margin-top: 100%;
        opacity: 0;
    }
}


